.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #666666;
}

@media (max-width: 576px) {
  .hide-on-mobile {
    display: none;
  }

  body.login-page {
    background: none !important; /* Supprime l'image d'arrière-plan sur mobile */
    background-color: #ffffff !important; /* Conserve la couleur de fond */
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0; /* Couleur de fond par défaut */
}

body.login-page {
  background: url('../img/wave.png') no-repeat;
  background-size: contain;
  background-position: 0px 2px;
}

.welcome-text {
  font-family: "Century Gothic", sans-serif; /* Utilisation de Century Gothic avec une sauvegarde sur sans-serif */
}
