.sidebar{
  &[data-color="black"]:after{
      @include icon-gradient(#282828, rgba(#111,.7));
  }
  .logo-img img {
    max-width: 60px;
    max-height: 60px;
  }
  .user-img img {
    margin-top: 15px;
    max-width: 50px;
    max-height: 50px;
  }
}

@media (max-width: 991px){
  .sidebar .nav > li.active.active-pro {
    position: relative;
    bottom: unset;
  }
}

#bodyClick {
  position: absolute;
}
