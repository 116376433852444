.chevron-icon {
    transition: transform 0.4s ease;
}

/* .nav-link-parent.active .chevron-icon {
    transform: rotate(180deg); /* Rotate chevron to point up when active */


  .nav-link-parent.active ul li:nth-child(1) .nav-link {
    background: none !important;
    opacity: 0.86 !important;
  }
  
  .nav-link-parent.active ul li:nth-child(1) .nav-link:hover {
    background: rgba(255, 255, 255, 0.23) !important;
    opacity: 1 !important;
  }
  
  .nav-link-parent.active ul li:nth-child(1) .nav-link.active {
    background: rgba(255, 255, 255, 0.23) !important;
    opacity: 1 !important;
  }

  .nav-link-parent.active ul li:nth-child(2) .nav-link {
    background: none !important;
    opacity: 0.86 !important;
  }
  
  .nav-link-parent.active ul li:nth-child(2) .nav-link:hover {
    background: rgba(255, 255, 255, 0.23) !important;
    opacity: 1 !important;
  }
  
  .nav-link-parent.active ul li:nth-child(2) .nav-link.active {
    background: rgba(255, 255, 255, 0.23) !important;
    opacity: 1 !important;
  }

  .nav-link#devis-h:hover {
    background: rgba(255, 255, 255, 0.13);
    opacity: 1;
  }
  